/** @jsx jsx */
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { Image, Layout, SEO, SlicesWrapper } from '../components'
import { EventCards, Newsletter } from '../components/Slices'
import linkResolver from '../utils/linkResolver'

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    prismicHomepage {
      _previewable
      url
      data {
        meta_description
        meta_title
        og_image {
          url
          alt
        }
        hero_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicHomepageDataBodyQuoteAndText {
            slice_type
            primary {
              quote {
                text
              }
              quote_description
              image {
                gatsbyImageData
              }
              text {
                raw
              }
              button_link {
                url
              }
              button_text
            }
          }
          ... on PrismicHomepageDataBodyTourCards {
            slice_type
          }
        }
      }
    }
  }
`

const Page = ({ data, pageContext }) => {
  const doc = data.prismicHomepage
  if (!doc) return null

  const { now: timestamp } = pageContext

  return (
    <Layout aboveImage>
      <SEO
        title={doc.data.meta_title || data.site.siteMetadata.title}
        description={doc.data.meta_description}
        og={{
          image: doc.data.og_image.url,
          alt: doc.data.og_image.alt,
        }}
      />
      <h1 sx={{ variant: 'visuallyHidden' }}>{data.site.siteMetadata.title}</h1>
      <Image
        alt={doc.data.hero_image.alt}
        image={doc.data.hero_image.gatsbyImageData}
        fullWidth
        objectPosition="right center"
        sx={{
          variant: 'overlay',
          height: [400, 600],
          img: { filter: 'grayscale(1) contrast(0.75) brightness(1.25)' },
        }}
      />
      <SlicesWrapper slices={doc.data.body} />
      <EventCards timestamp={timestamp} />
      <Newsletter />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    now: PropTypes.string.isRequired,
  }),
}

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
